import { Component, OnInit } from '@angular/core';
import { Meta, MetaDefinition, Title  } from '@angular/platform-browser';
declare let $: any;

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  constructor(private metaService:Meta,private title: Title,private titleService: Title,
    private metaTagService: Meta) { }

  ngOnInit() {
    $(function() {
      $('.accordion').find('.accordion-title').on('click', function(){
          // Adds Active Class
          $(this).toggleClass('active');
          // Expand or Collapse This Panel
          $(this).next().slideToggle('fast');
          // Hide The Other Panels
          $('.accordion-content').not($(this).next()).slideUp('fast');
          // Removes Active Class From Other Titles
          $('.accordion-title').not($(this)).removeClass('active');
      });
    });

    this.title.setTitle("FAQ for Trading Software | Robot Trading Related Q&A ");
    this.metaService.updateTag( { name:'description',content:"How Long Does Auto Trading Software Run & Its Validity? Once installed and configured the Auto trading setup, it can be run for lifetime access without any monthly or yearly subscription & no profit sharing. "});
    this.metaService.updateTag( { name:'keywords',content:"frequently asked questions, FAQ, trading software faq, FAQ for trading software, robot trading faq, forex trading faq, forex robot faq, FAQ for forex trading, faq for forex bot. robot trading software faq, faq for forex trading, forex bot faq, trading software faq, auto trading software, forex, forex robot trading, robot trading"});
    this.metaService.updateTag( { name:'robots',content:"index,follow"});
    this.metaService.updateTag( { name:'viewport',content:"width=device-width, initial-scale=1"});
  }

}
