import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { FeaturesComponent } from './features/features.component';
import { FeaturesDetailsComponent } from './features-details/features-details.component';
import { ServicesComponent } from './services/services.component';
import { ServicesDetailsComponent } from './services-details/services-details.component';
import { ProjectComponent } from './project/project.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { TeamComponent } from './team/team.component';
import { BlogComponent } from './blog/blog.component';
import { GetStartedComponent } from './get-started/get-started.component';
import { BlogDetailsComponent } from './blog/blog-details/blog-details.component';
import { ContactComponent } from './contact/contact.component';
import { PricingComponent } from './pricing/pricing.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { RefundComponent } from './refund/refund.component';
import { FaqComponent } from './faq/faq.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { DevelopersComponent } from './developers/developers.component';
import { WebHostingComponent } from './web-hosting/web-hosting.component';
import { RepairComponent } from './repair/repair.component';
import { IotComponent } from './iot/iot.component';
import { MachineLearningComponent } from './machine-learning/machine-learning.component';
import { MachineLearningHome2Component } from './machine-learning-home2/machine-learning-home2.component';
import { SupportComponent } from './support/support.component';

const routes: Routes = [
  // { path: '', redirectTo: '/home', pathMatch: 'full' }, // for defaault redirecting
  { path: '', component: HomeComponent },
  { path: 'developers', component: DevelopersComponent },
  { path: 'web-hosting', component: WebHostingComponent },
  { path: 'repair', component: RepairComponent },
  { path: 'get-started', component: GetStartedComponent },
  { path: 'iot', component: IotComponent },
  { path: 'machine-learning', component: MachineLearningComponent },
  { path: 'machine-learning-2', component: MachineLearningHome2Component },
  { path: 'support', component: SupportComponent },
  { path: 'about', component: AboutComponent },
  { path: 'features', component: FeaturesComponent },
  { path: 'features-details', component: FeaturesDetailsComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'services-details', component: ServicesDetailsComponent },
  { path: 'project', component: ProjectComponent },
  { path: 'project-details', component: ProjectDetailsComponent },
  { path: 'team', component: TeamComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'blog-details', component: BlogDetailsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'refund', component: RefundComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'coming-soon', component: ComingSoonComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
