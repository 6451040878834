import { Component, OnInit } from '@angular/core';
import { Meta, MetaDefinition, Title  } from '@angular/platform-browser';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  constructor(private metaService:Meta,private title: Title,private titleService: Title,
    private metaTagService: Meta) { }

  ngOnInit() {

    this.title.setTitle("Forex Trading - Terms and Conditions | Auto Trading Robot ");
    this.metaService.updateTag( { name:'description',content:"It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes."});
    this.metaService.updateTag( { name:'keywords',content:"terms and conditions, robot trading software terms, terms and conditions for forex trading, forex bot conditions, trading software, auto trading software, forex, forex robot trading, robot trading, forex trading software, terms and conditions for forex robot software, auto robot trading, forex robot, auto trading robot"});
    this.metaService.updateTag( { name:'robots',content:"index,follow"});
    this.metaService.updateTag( { name:'viewport',content:"width=device-width, initial-scale=1"});

  }

}
