import { Component, OnInit } from '@angular/core';
import { Meta, MetaDefinition, Title  } from '@angular/platform-browser';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {

  constructor(private metaService:Meta,private title: Title,private titleService: Title,
    private metaTagService: Meta) { }

  ngOnInit() {

    this.title.setTitle("Forex Trading Robot Software Price | Forex Robot Price");
    this.metaService.updateTag( { name:'description',content:"Our pricing plans (Basic Plan and Advanced Plan) are pointed toward guaranteeing that the Forex Robot Trading Software is the most accessible trading software in the market."});
    this.metaService.updateTag( { name:'keywords',content:"trading software price, cost of forex robot trading, forex bot price, trading software price, cost for auto trading software, forex robot cost, forex trading software price details, forex robot software price, auto robot trading, cost of auto trading robot, auto trading robot software pricing details, robot software price"});
    this.metaService.updateTag( { name:'robots',content:"index,follow"});
    this.metaService.updateTag( { name:'viewport',content:"width=device-width, initial-scale=1"});

  }

}
