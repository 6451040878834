import { Component, OnInit } from '@angular/core';
import { Meta, MetaDefinition, Title  } from '@angular/platform-browser';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {

  constructor(private metaService:Meta,private title: Title,private titleService: Title,
    private metaTagService: Meta) { }

  ngOnInit() {

    this.title.setTitle("Support - Forex Trading Software | Robot Trading Assistance ");
    this.metaService.updateTag( { name:'description',content:"We are here to support you 24 X 7 and just raise a ticket, Fill out the form on our webpage and we'll get in touch with you as soon as possible."});
    this.metaService.updateTag( { name:'keywords',content:"robot trading software, forex trading assistance, forex bot, best trading software, auto trading software, forex, forex robot trading support, robot trading, forex trading software, forex robot software, auto robot trading, forex robot support, auto trading robot, auto trading robot software, robot software"});
    this.metaService.updateTag( { name:'robots',content:"index,follow"});
    this.metaService.updateTag( { name:'viewport',content:"width=device-width, initial-scale=1"});

  }

}
