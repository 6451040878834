import { Component, OnInit } from '@angular/core';
import { Meta, MetaDefinition, Title  } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  constructor(private metaService:Meta,private title: Title,private titleService: Title,
    private metaTagService: Meta) { }

  ngOnInit() {

    this.title.setTitle("Privacy and policy for Forex Robot Trading");
    this.metaService.updateTag( { name:'description',content:"As you would expect, if you are unable to or choose not to provide us (or your Administrator) with the personal information required for the performance of the Sites, we may be unable to provide you with the Sites you have requested."});
    this.metaService.updateTag( { name:'keywords',content:"privacy and policy, privacy statement, robot trading software, forex trading policy, forex bot, privacy and policy for trading software, auto trading software, forex robot trading policies, privacy and policy of robot trading, forex trading software, auto robot trading"});
    this.metaService.updateTag( { name:'robots',content:"index,follow"});
    this.metaService.updateTag( { name:'viewport',content:"width=device-width, initial-scale=1"});

  }

}
