import { Component, OnInit } from '@angular/core';
import { Meta, MetaDefinition, Title  } from '@angular/platform-browser';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent implements OnInit {

  constructor(private metaService:Meta,private title: Title,private titleService: Title,
    private metaTagService: Meta) { }

  ngOnInit() {

    this.title.setTitle("Best Trading Software Features - Auto Trading Robot ");
    this.metaService.updateTag( { name:'description',content:"Forex Robot does the Consistent trading - Auto Trading Robots always adhere to the rules you set for them and the robot will trade simultaneously, across various markets, on different currency pairs."});
    this.metaService.updateTag( { name:'keywords',content:"robot trading software features, forex trading features, features of forex bot, trading software features, features of auto trading software, forex features, forex robot trading features, robot trading features, forex trading software features, features of  forex robot software, auto robot trading, features of forex robot, auto trading robot, auto trading robot software, robot software, best trading software features"});
    this.metaService.updateTag( { name:'robots',content:"index,follow"});
    this.metaService.updateTag( { name:'viewport',content:"width=device-width, initial-scale=1"});

  }

}
