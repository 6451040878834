import { Component, OnInit } from '@angular/core';
import { Meta, MetaDefinition, Title  } from '@angular/platform-browser';

@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.css']
})
export class RefundComponent implements OnInit {

  constructor(private metaService:Meta,private title: Title,private titleService: Title,
    private metaTagService: Meta) { }

  ngOnInit() {

    this.title.setTitle("Refund Policy - Forex Robot Trading Software");
    this.metaService.updateTag( { name:'description',content:"Please allow up to 72 hrs for our team to review your details and process your refund or transfer of license request depending on the nature of the inquiry."});
    this.metaService.updateTag( { name:'keywords',content:"Refund policy, forex trading, refund policy and conditions, forex bot, trading software refund policy, auto trading software, forex, refund policy for forex robot trading, robot trading policies, forex trading software refund terms, forex robot software, auto robot trading, forex robot"});
    this.metaService.updateTag( { name:'robots',content:"index,follow"});
    this.metaService.updateTag( { name:'viewport',content:"width=device-width, initial-scale=1"});

  }

}
